import React from  'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

const Blog = ({ data, location }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout location={location}>
      <Helmet title={`Threefields Media - Blog`} />
      <div className="grid lg:grid-cols-2 gap-6 text-primary">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <Link className="blog-post-preview flex flex-col justify-between rounded-lg" to={post.frontmatter.path} key={post.id}>
                <GatsbyImage
                  image={getImage(post.frontmatter.featuredImage)}
                  alt={post.frontmatter.title}
                />
              <div className="flex flex-col justify-start pr-2 pl-2">
                <h3 className="text-2xl font-bold">{post.frontmatter.title}</h3>
                <div>
                  <span className="date badge mt-1">{post.frontmatter.date}</span>
                </div>
              </div>
              <div className="flex flex-col justify-end p-2">
                  <p className="mt-2">{post.excerpt}</p>
                  <div className="flex justify-end">
                    <button className="readmore">
                      Read More
                    </button>
                  </div>
                </div>
              </Link>
            )
          })
        }
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
